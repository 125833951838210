import React from 'react';
import styled from 'styled-components';
import { Section } from '../section';
import { StaticImage } from 'gatsby-plugin-image';
import { ANCHOR_PROJECT } from '../../routes';
import { Title3 as DefaultTitle3, Title2, Title2Wrapper as DefaultTitle2Wrapper } from '../Headings';
import { ContentWrapper } from '../common';

const Title3 = styled(DefaultTitle3)`
  color: ${({theme}) => theme.colors.primary};
  text-align: center;
  margin-bottom: 50px;

  @media(min-width: 992px) {
    margin-bottom: 70px;
  }
`;

const Title2Wrapper = styled(DefaultTitle2Wrapper)`
  margin-bottom: 40px;

  @media(min-width: 992px) {
    margin-bottom: 90px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-left: -16px;
  margin-right: -16px;

  @media (min-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 992px) {
    margin: 0;
    grid-template-columns: 1fr 495px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 200px;

  .gatsby-image-wrapper {
    height: 100%;
  }

  .cover-img {
    object-fit: cover;
  }

  @media (min-width: 576px) {
    height: 280px;
  }

  @media (min-width: 768px) {
    height: 350px;
  }

  @media (min-width: 992px) {
    height: 440px;
  }
`;

const InfoBox = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.primary_muted};
  padding: 30px 16px 30px 16px;
  margin-bottom: 100px;
  /* border-bottom-left-radius: 250px;
  border-bottom-right-radius: 250px; */

  &:after {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: ${({theme}) => theme.colors.primary_muted};
    border-radius: 0 0 50% 50%/0 0 100% 100%;
  }

  p {
    line-height: 1.875rem;
    margin-bottom: 32px;
  }

  p.es-highlighted {
    font-weight: 700;
    margin: 0;
  }

  @media (min-width: 992px) {
    padding: 84px 110px 84px 70px;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 250px;
    margin-bottom: 0;

    &::after {
      content: none;
    }
  }
`;


function AboutProjectSection() {
  return (
    <Section id={ANCHOR_PROJECT}>
      <ContentWrapper>
        <Title3 as="p">
          Poslední volné jednotky k&nbsp;prodeji
        </Title3>
        <Title2Wrapper>
          <Title2>
            O&nbsp;projektu
          </Title2>
          <p>
            Třešňovka je 4&nbsp;podlažní bytový komplex 26&nbsp;bytových jednotek a&nbsp;2&nbsp;ateliérů o&nbsp;různých dispozicích, od menších 1+kk až po prostorné 3+kk.
          </p>
        </Title2Wrapper>
        <Grid>
          <ImageWrapper>
            <StaticImage
              imgClassName="cover-img"
              src="../../images/img-n-01.jpg" layout="fullWidth" placeholder="blurred" alt="Foto" />
          </ImageWrapper>
          <InfoBox>
            <p>
              Byty jsme navrhli ve velmi kvalitním provedení a&nbsp;v&nbsp;moderním vzdušném designu. Každá bytová jednotka disponuje balkonem, lodžií, terasou nebo předzahrádkou s&nbsp;výhledem do zeleně či přilehlé vilové čtvrti.
            </p>
            <p className="es-highlighted">Dokončení plánujeme na 3Q&nbsp;2025.</p>
          </InfoBox>
        </Grid>
      </ContentWrapper>
    </Section>
  );
}

export default AboutProjectSection;