import React from 'react';
import styled from 'styled-components';
import { Section } from '../section';
import { StaticImage } from 'gatsby-plugin-image';
import { Title3 as DefaultTitle3, Title2, Title2Wrapper as DefaultTitle2Wrapper } from '../Headings';
import { ContentWrapper } from '../common';
import { ANCHOR_LOCALITY } from '../../routes';
import MapSection from '../MapSection';

const Wrapper = styled.div`
  @media (min-width: 1200px) {
    padding-bottom: 95px;
  }
`;

const TopSection = styled(Section)`
  padding-top: 40px;

  @media (min-width: 992px) {
    padding-top: 120px;
  }
`;

const Title3 = styled(DefaultTitle3)`
  color: ${({theme}) => theme.colors.primary};
  text-align: center;
  margin-bottom: 60px;
`;

const Title2Wrapper = styled(DefaultTitle2Wrapper)`
  margin-bottom: 40px;

  @media (min-width: 992px) {
    margin-bottom: 60px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 40px;

  @media (min-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 992px) {
    margin: 0;
    grid-template-columns: 495px 1fr;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 200px;
  order: 1;

  .gatsby-image-wrapper {
    height: 100%;
  }

  .cover-img {
    object-fit: cover;
  }

  @media (min-width: 576px) {
    height: 280px;
  }

  @media (min-width: 768px) {
    height: 350px;
  }

  @media (min-width: 992px) {
    height: 440px;
    order: 2;
  }
`;

const InfoBox = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.primary_muted};
  padding: 30px 16px 30px 16px;
  /* border-bottom-left-radius: 250px;
  border-bottom-right-radius: 250px; */
  margin-bottom: 100px;
  order: 2;

  &:after {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: ${({theme}) => theme.colors.primary_muted};
    border-radius: 0 0 50% 50%/0 0 100% 100%;
  }

  p {
    line-height: 1.875rem;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    order: 1;
    padding: 84px 70px 84px 110px;
    border-top-left-radius: 250px;
    border-bottom-left-radius: 250px;
    margin-bottom: 0;

    &::after {
      content: none;
    }
  }
`;

function LocationSection() {
  return (
    <Wrapper id={ANCHOR_LOCALITY}>
      <TopSection>
        <ContentWrapper>
          <Title3 as="p">
            Vše důležité na dosah a&nbsp;únik z&nbsp;ruchu města
          </Title3>
          <Grid>
            <InfoBox>
              <p>
                Rezidence se nachází 5&nbsp;minut od historického centra Kolína a&nbsp;přímo u&nbsp;výjezdové komunikace na Prahu (dojezdová vzdálenost je asi 40&nbsp;minut). Zároveň ale poskytuje klidné zázemí a&nbsp;atmosféru sousedící vilové čtvrti. Blízko je rozhledna Vodárna a&nbsp;malebný třešňový sad, po němž Třešňovka nese své jméno.
              </p>
            </InfoBox>
            <ImageWrapper>
              <StaticImage
                imgClassName="cover-img"
                src="../../images/img-n-02.jpg" layout="fullWidth" placeholder="blurred" alt="Foto" />
            </ImageWrapper>
          </Grid>
        </ContentWrapper>
      </TopSection>
      <TopSection>
        <ContentWrapper>
          <Title2Wrapper>
            <Title2>
              Lokalita
            </Title2>
            <p>
              Třešňovka představuje příjemnou kombinaci bydlení v&nbsp;klidné vilové čtvrti s&nbsp;dobrou dostupností do okolních směrů.
            </p>
          </Title2Wrapper>
        </ContentWrapper>
      </TopSection>
      <MapSection />
    </Wrapper>
  );
}

export default LocationSection;