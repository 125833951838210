import React from 'react';
import styled from 'styled-components';
import { Section as DefaultSection } from '../section';
import SectionWrapper from '../sectionWrapper';
import { ANCHOR_GALLERY } from '../../routes';
import { Title3 } from '../Headings';
import GalleryPanel from '../GalleryPanel';

const Section = styled(DefaultSection)`
  padding-top: 40px;

  ${Title3} {
    text-align: center;
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {
    padding-top: 105px;

    ${Title3} {
      margin-bottom: 60px;
    }
  }
`;

function GallerySection() {
  return (
    <SectionWrapper id={ANCHOR_GALLERY}>
      <Section>
        <Title3 as="h2">
          Galerie
        </Title3>
        <GalleryPanel folder="landing" data={[1,2,3,4,5,6,7,8,9,10,11,12,13]} />
      </Section>
    </SectionWrapper>
  );
}

export default GallerySection;