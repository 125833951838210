import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import locationSvg from '../../images/svg/location.svg';

const LocationIcon = styled(locationSvg)`
  fill: ${({theme}) => theme.colors.primary};
  width: 30px;
  height: auto;
  transform: translate(-15px, -20px);
`;

const MapWrapper = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  border-radius: 600px;
  overflow: hidden;
  outline: 0;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  @media (min-width: 768px) {
    width: 500px;
    height: 500px;
    margin: 0 auto;
  }

  @media (min-width: 992px) {
    order: 2;
  }

  @media (min-width: 1200px) {
    width: 645px;
    height: 645px;
  }
`;


function Map() {
  return (
    <MapWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCD0UEwhfeQDazhyHYChFxR1L8Wj0Lj3Bg' }}
        defaultCenter={{
          lat: 50.030109,
          lng: 15.188943
        }}
        defaultZoom={15}
        options={{
          styles: [
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ],
        }}
      >
        <LocationIcon
          lat="50.0308392"
          lng="15.1856597"
        />
    </GoogleMapReact>
  </MapWrapper>
  );
}

export default Map;