import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { Section as DefaultSection } from '../section';
import SectionWrapper from '../sectionWrapper';
import { ContentWrapper } from '../common';
import { Title3 } from '../Headings';

const Section = styled(DefaultSection)`
  padding-top: 40px;
  padding-bottom: 60px;

  ${Title3} {
    text-align: center;
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 100px;

    ${Title3} {
      text-align: center;
      margin-bottom: 50px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 1px;
    background-color: ${({theme}) => theme.colors.primary};
    z-index: 0;
  }

  @media (min-width: 992px) {
    padding: 0 60px;
    flex-direction: row;

    &::before {
      content: '';
      position: absolute;
      top: 51px;
      left: 60px;
      right: 60px;
      height: 1px;
      width: auto;
      background-color: ${({theme}) => theme.colors.primary};
      z-index: 0;
    }
  }
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  padding: 16px;
  border: 1px solid ${({theme}) => theme.colors.primary};
  background-color: ${({theme}) => theme.colors.primary_muted};
  position: relative;


  p {
    font-family: 'Oswald', Georgia, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: ${({theme}) => theme.colors.primary};
    margin: 0;
  }

  &.es-done {
    background-color: ${({theme}) => theme.colors.primary};

    p {
      color: ${({theme}) => theme.colors.white};
    }
  }
`;

const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  span {
    display: block;
    font-family: 'Oswald', Georgia, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primary};
  }

  @media (max-width: 991px) {
    span {
      position: absolute;
      left: calc(50% - 150px);
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.es-right {
      span {
        left: auto;
        left: calc(50% + 150px);
      }
    }
  }
`;

const VideoController = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const VideoWrapper = styled.div`
  position: relative;
  /* max-width: 870px; */
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  margin-top: 60px;
  padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: 1200px) {
    margin-top: 100px;
  }
`;

function TimelineSection () {
  return (
    <SectionWrapper $bgColor="primary_muted">
      <Section>
        <ContentWrapper>
          <Title3 as="h2">Jak se projekt vyvíjí?</Title3>
          <Wrapper>
            <Item className="es-right">
              <Bullet className="es-done">
                <p>
                  Projekt v&nbsp;přípravě
                </p>
              </Bullet>
              <span>1Q&nbsp;2023</span>
            </Item>
            <Item>
              <Bullet className="es-done">
                <p>
                  Prodej zahájen
                </p>
              </Bullet>
              <span>3Q&nbsp;2023</span>
            </Item>
            <Item className="es-right">
              <Bullet className="es-done">
                <p>
                  Výstavba v&nbsp;procesu
                </p>
              </Bullet>
              <span>1Q&nbsp;2024</span>
            </Item>
            <Item>
              <Bullet>
                <p>
                  Projekt dokončen
                </p>
              </Bullet>
              <span>3Q&nbsp;2025</span>
            </Item>
          </Wrapper>
          <VideoController>
            <VideoWrapper className="video-container">
              <ReactPlayer
                className="react-player"
                url="https://vimeo.com/1062800346"
                width="100%"
                height="100%"
                controls={true}
                />
            </VideoWrapper>
          </VideoController>
        </ContentWrapper>
      </Section>
    </SectionWrapper>
  );
}

export default TimelineSection;