import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import TimelineSection from "../components/TimelineSection";
import AboutProjectSection from "../components/AboutProjectSection";
import UnitSelectionSection from "../components/UnitSelectionSection";
import LocationSection from "../components/LocationSection";
import LandingTopPanel from "../components/LandingTopPanel";
import GallerySection from "../components/GallerySection";

function IndexPage () {
  return (
    <Layout>
      <Seo />
      <LandingTopPanel />
      <AboutProjectSection />
      <UnitSelectionSection />
      <LocationSection />
      <GallerySection />
      <TimelineSection />
    </Layout>
  );
}

export default IndexPage;
