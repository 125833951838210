import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useMediaQuery } from 'react-responsive';
import { Title1 } from '../Headings';
import { ContentWrapper as DefaultContentWrapper } from '../common';
import { ANCHOR_PROJECT } from '../../routes';
import arrowSrc from '../../images/svg/arrow-down-2.svg';

const ArrowIcon = styled(arrowSrc)`
`;

const Wrapper = styled.div`
  height: calc(100svh - 60px);
  min-height: 570px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
  }

  @media (min-width: 992px) {
    min-height: 700px;

    &::after {
      height: 350px;
    }
  }
`;

const ContentWrapper = styled(DefaultContentWrapper)`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  @media (min-width: 992px) {
    bottom: 110px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }

  .cover-img {
    object-fit: cover;
    object-position: center 80%;
  }
`;

const BtnWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;

  a, a:hover {
    color: ${({theme}) => theme.colors.white};
    text-decoration: none;
  }

  @media (min-width: 992px) {
    bottom: 40px;
  }
`;

const BulletButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  padding: 16px;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({theme}) => theme.colors.primary};
  position: relative;
  transition: all ease 0.3s;

  p {
    font-family: 'Oswald', Georgia, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: ${({theme}) => theme.colors.white};
    margin: 0;
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.colors.primary_hover};
  }
`;

function LandingTopPanel() {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  return (
    <Wrapper>
      <ImageWrapper>
        <StaticImage src="../../images/landing-panel-01.jpg" imgClassName="cover-img" placeholder="blurred" alt="Foto" />
      </ImageWrapper>
      <ContentWrapper>
        <Title1>
          {
            isMobile ? (
              <span>Splněný<br />sen moderního bydlení</span>
            ) : (
              <span>Splněný sen moderního bydlení</span>
            )
          }
        </Title1>
      </ContentWrapper>
      <BtnWrapper>
        <AnchorLink to={`/#${ANCHOR_PROJECT}`}>
          <BulletButton>
            <p>Prozkoumejte třešňovku</p>
            <ArrowIcon />
          </BulletButton>
        </AnchorLink>
      </BtnWrapper>
    </Wrapper>
  );
}

export default LandingTopPanel;