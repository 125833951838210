import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import arrowSvg from '../../images/svg/arrow-left.svg';

const ArrowIcon = styled(arrowSvg)`
  fill: ${({theme}) => theme.colors.white};
  transform: rotateZ(-180deg);
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${({theme}) => theme.colors.primary};
  transition: all ease 0.3s;
`;

const Th = styled.th`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  text-align: left;
  padding: 0 16px 30px;
  color: ${({theme}) => theme.colors.black};

  &.sm, &.md, &.lg {
    display: none;
  }

  &.td-center {
    text-align: center;
  }

  span.dp-view-label {
    display: none;
  }

  @media (min-width: 576px) {
    &.sm {
      display: table-cell;
    }
  }

  @media (min-width: 768px) {
    padding: 0 20px 34px;
    font-size: 1rem;

    &.md {
      display: table-cell;
    }

    span.mb-view-label {
      display: none;
    }

    span.dp-view-label {
      display: inline;
    }
  }

  @media (min-width: 992px) {
    min-width: 50px;

    &.lg {
      display: table-cell;
    }
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
  }
`;

const Table = styled.table`
  width: 100%;
  /* margin: 50px 0; */

  span.dp-view-label {
    display: none;
  }

  span.es-id-value {
    font-weight: 700;
    color: ${({theme}) => theme.colors.primary};
  }

  tbody tr td.es-link {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 30px;
  }

  tbody tr td {
    color: ${({theme}) => theme.colors.black};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: 60px;
    padding: 18px 16px;
    text-align: left;
    height: 60px;
    vertical-align: middle;

    a, a:hover {
      color: ${({theme}) => theme.colors.primary};
      text-decoration: none;
    }

    a:hover {
      color: ${({theme}) => theme.colors.primary_hover};
    }

    a.download-link {
      display: block;
      line-height: 1;
    }

    a.detail-link {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;

      span {
        display: none;
        font-size: 1.25rem;
        line-height: 1.875rem;
        font-family: 'Oswald', Georgia, sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        color: ${({theme}) => theme.colors.primary};
      }

      &:hover {
        span {
          color: ${({theme}) => theme.colors.primary_hover};
        }

        ${Bullet} {
          background-color: ${({theme}) => theme.colors.primary_hover};
        }
      }

      @media(min-width: 1200px) {
        span {
          display: block;
        }
      }
    }
  }

  tbody tr:nth-child(odd) {
    background-color: ${({theme}) => theme.colors.bg_row_odd};
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }

  tbody tr td.sm, tbody tr td.md, tbody tr td.lg {
    display: none;
  }

  tbody tr td.td-center {
    text-align: center;
  }

  tbody tr td.td-right {
    text-align: right;
  }

  tbody tr td.td-icon {
    padding: 0;
    vertical-align: middle;
  }

  tbody tr:hover:not(.unit-sold) td {
    background-color: ${({theme}) => theme.colors.primary_muted};
    cursor: pointer;

    a {
      color: ${({theme}) => theme.colors.secondary_500};
    }
  }

  tbody tr.unit-sold td {
    color: ${({theme}) => theme.colors.primary_300};
    opacity: 0.3;
  }

  @media (min-width: 576px) {
    tbody tr td.sm {
      display: table-cell;
    }
  }

  @media (min-width: 768px) {
    tbody tr td {
      padding: 18px 20px;
    }

    tbody tr td.md {
      display: table-cell;
    }

    span.mb-view-label {
      display: none;
    }

    span.dp-view-label {
      display: inline;
    }
  }

  @media (min-width: 992px) {

    tbody tr td.lg {
      display: table-cell;
    }
  }

  @media (min-width: 1200px) {

    tbody tr td.es-link {
      width: 140px;
    }
  }
`;

const TableWrapper = styled.div`
  margin-left: -16px;
  margin-right: -16px;

  @media (min-width: 576px) {
    margin: 0;
  }
`;

function UnistGrid({data, isAtelier}) {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  return (
    <TableWrapper>
      <Table>
        <thead>
            <tr>
              <Th>
                { isMobile ? (
                    <span>#</span>
                  ) : (
                    <>
                      {isAtelier ? 'Ateliér' : 'Byt'}
                    </>
                  )
                }
              </Th>
              <Th className="md">Podlaží</Th>
              <Th>{ !isMobile ?  'Dispozice' : ''}</Th>
              <Th className="lg">Zahrádka/balkon</Th>
              <Th className="sm">Plocha</Th>
              <Th>Cena</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((item, idx) => (
                <tr key={item.id}>
                  <td onClick={() => navigate(`/detail/${item.id}`)}>
                    <span className="es-id-value">{item.id}</span>
                  </td>
                  <td
                    className="md"
                    onClick={() => navigate(`/detail/${item.id}`)}
                  >{item.floor}&nbsp;{item.isAtelier ? 'PP' : 'NP'}</td>
                  <td onClick={() => navigate(`/detail/${item.id}`)}>{item.type}</td>
                  <td
                    className="lg"
                    onClick={() => navigate(`/detail/${item.id}`)}
                  >{(item.garden || item.balcony) ? <span>{new Intl.NumberFormat('cs-CZ').format(item.garden || item.balcony)}&nbsp;m<sup>2</sup></span> : '-'}</td>
                  <td className="sm" onClick={() => navigate(`/detail/${(item.id)}`)}>{new Intl.NumberFormat('cs-CZ').format(item.sum_area)}&nbsp;m<sup>2</sup></td>
                  <td onClick={() => navigate(`/detail/${item.id}`)}>{item.price > 0 ? new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(Math.round(item.price)) : '-'}</td>
                  <td className="es-link">
                    <Link className="detail-link" to={`/detail/${item.id}`}>
                      <span>Detail</span>
                      <Bullet>
                        <ArrowIcon />
                      </Bullet>
                    </Link>
                  </td>
                </tr>
              ))
            }
        </tbody>
      </Table>
    </TableWrapper>
  )
}

export default UnistGrid;