import React from 'react';
import styled from 'styled-components';
import { Section } from '../section';
import { ANCHOR_APARTMENT_SELECTION, ANCHOR_ATELIER_SELECTION } from '../../routes';
import { Title3 as DefaultTitle3, Title2, Title2Wrapper as DefaultTitle2Wrapper } from '../Headings';
import { ContentWrapper } from '../common';
import UnistGrid from '../UnitsGrid';
import data from '../../../data.json';

const Title3 = styled(DefaultTitle3)`
  color: ${({theme}) => theme.colors.primary};
  text-align: center;
  margin-bottom: 50px;

  @media(min-width: 992px) {
    margin-bottom: 70px;
  }
`;

const Title2Wrapper = styled(DefaultTitle2Wrapper)`
  margin-bottom: 40px;

  @media(min-width: 992px) {
    margin-bottom: 60px;
  }
`;

function UnitSelectionSection() {

  const unitsData = data.filter(i => i.state === 'available' && !i.isAtelier);
  const ateliersData = data.filter(i => i.state === 'available' && i.isAtelier);

  return (
    <>
      <Section id={ANCHOR_APARTMENT_SELECTION}>
        <ContentWrapper>
          <Title3 as="p">
            Stylová rezidence pro náročné
          </Title3>
          <Title2Wrapper>
            <Title2>
              Byty
            </Title2>
            <p>
              Prohlédněte si poslední volné bytové jednotky:
            </p>
          </Title2Wrapper>
          <UnistGrid data={unitsData} />
        </ContentWrapper>
      </Section>
      <Section id={ANCHOR_ATELIER_SELECTION}>
        <ContentWrapper>
          <Title2Wrapper>
            <Title2>
              Ateliéry
            </Title2>
            <p>
              Prohlédněte si poslední volné nebytové jednotky:
            </p>
          </Title2Wrapper>
          <UnistGrid data={ateliersData} isAtelier />
        </ContentWrapper>
      </Section>
    </>
  );
}

export default UnitSelectionSection;