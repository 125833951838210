import React from 'react';
import styled from 'styled-components';
import SectionWrapper from '../sectionWrapper';
import { Section as DefaultSection } from '../section';
import { Title3 as DefaultTitle3 } from '../Headings';
import { ContentWrapper } from '../common';
import Map from '../Map';

const Title3 = styled(DefaultTitle3)`
  color: ${({theme}) => theme.colors.primary};
  margin-bottom: 30px;
  text-align: center;

  @media (min-width: 992px) {
    margin-bottom: 60px;
  }
`;

const Section = styled(DefaultSection)`
  padding-top: 50px;
  padding-bottom: 50px;

  @media (min-width: 992px) {
    height: 700px;
    padding-bottom: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 645px;
    align-items: inherit;
    gap: 70px;
  }
`;

const InfoBox = styled.div`
  color: ${({theme}) => theme.colors.black};
  order: 2;

  ul {
    margin-bottom: 0;
    li {
      line-height: 1.625rem;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 992px) {
    order: 1;
  }

  @media (min-width: 1200px) {
    padding-left: 110px;
    padding-top: 100px;
  }
`;

function MapSection() {
  return (
    <SectionWrapper $bgColor="primary_muted">
      <Section>
        <ContentWrapper>
          <Title3>
            Skvělá dopravní dostupnost
          </Title3>
          <Grid>
            <InfoBox>
              <ul>
                <li>do centra Kolína dojedete za 5&nbsp;minut, do Prahy za 40&nbsp;minut</li>
                <li>v&nbsp;těsném sousedství areálu se nachází supermarket Lidl</li>
                <li>veškerá další občanská vybavenost je dostupná v&nbsp;blízkém okolí</li>
                <li>MHD zastavuje hned u&nbsp;areálu rezidence (stanice Lučební)</li>
              </ul>
            </InfoBox>
            <Map className="es-map" />
          </Grid>
        </ContentWrapper>
      </Section>
    </SectionWrapper>
  );
}

export default MapSection;